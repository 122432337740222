.cust-search-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  padding: 20px;
  background-color: #f9f9f9;
  width:100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.search-container {
  flex: 1;
  max-width: 400px;
  margin-right: 20px;
}

.search-container label {
  font-weight: bold;
  margin-right: 10px;
}

.search-container input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: calc(100% - 110px);
}

.search-container button {
  padding: 10px 15px;
  background-color: #70AB0E;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-container button:hover {
  background-color: #609A0D;
}

.stats-container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.box {
  flex: 1;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  background-color: lightgray;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%; 
  height: 150px; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
}
.payment-history-scroll {
  max-height: 200px; /* Set to desired height */
  overflow-y: auto; /* Enable vertical scrolling */
}



.paid-box {
  background-color: #70AB0E80;
}

.unpaid-box {
  background-color: #E9BEBE;
}

.payment-info, .no-payment {
  margin-top: 20px;
  background-color: #F2F2F2;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.payment-info h2, .no-payment p {
  margin: 0;
  font-weight: bold;
}

.payment-details {
  display: flex; 
  align-items: stretch; 
}

.separator {
  width: 2px; 
  background-color: #79BA0F; 
  height: 100%; 
  margin: 0 20px; 
}

.history {
  display: flex;
  flex-direction: column; 
}


.payment-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  margin: 20px 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.payment-table thead {
  background-color: #007bff;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.payment-table th, .payment-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
}

.payment-table tbody tr:hover {
  background-color: #f1f1f1;
}

.payment-history-entry td {
  font-size: 14px;
  color: #333333;
}

.payment-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.no-payment-history {
  text-align: center;
  padding: 20px;
  color: #777;
  font-style: italic;
}

.separator {
  height: 1px;
  background-color: #eee;
  margin: 10px 0;
}

.payment-table tbody td {
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .payment-table thead {
    display: none;
  }
  
  .payment-table tr {
    display: block;
    margin-bottom: 10px;
  }

  .payment-table td {
    display: block;
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .payment-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
