@import url('https://fonts.googleapis.com/css2?family=Norwester&display=swap');

.font-norwester {
  font-family: 'Norwester', sans-serif;
}


.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-10 {
  top: 2.5rem; /* 10 * 0.25rem */
}

.right-20 {
  right: 5rem; /* 20 * 0.25rem */
}

.left-2 {
  left: 0.5rem; /* 2 * 0.25rem */
}

.top-1-2 {
  top: 50%;
}

/* Responsive styles */
@media (max-width: 768px) {
  .absolute.top-0.left-0.right-0 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .absolute.top-10.right-20 {
    right: 10px;
  }

  .absolute.left-2.top-1-2 {
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 4rem);
  }
}

.footer-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

@media (max-width: 600px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-container > div:first-child {
    margin-top: 20px;
    margin-left: 0;
  }

  .footer-container > div:last-child {
    margin-top: 20px;
    border-top: 1px solid #70AB0E;
    border-left: 1px solid #70AB0E;
  }

  .footer-container .lg-w-1-2 {
    width: 100%;
    border-right: 1px solid #70AB0E;
  }

  .footer-container .lg-w-1-2:last-child {
    border-right: 0;
  }
}
