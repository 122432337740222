/* scroll.css */
@keyframes scroll {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.scrolling-text {
  display: flex;
  white-space: nowrap;
  animation: scroll 15s linear infinite;
}
