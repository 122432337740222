.table-row {
  transition: background-color 0.3s ease;
  height: auto;
}

.table-row:hover {
  background-color: rgba(76, 175, 80, 0.3);
}.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.table-container::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 10px;
  box-shadow: inset 0 0 6px black;
}